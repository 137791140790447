import React, { useState, useEffect } from 'react';
import useAxios from 'hooks/useAxios';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    CardHeader,
    Button,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import ErrorBoundary from 'components/ErrorBoundary';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import CustomeDate from './Widgets/customeDate';
import Drawer from 'components/Drawer';
import HoverImage from 'react-hover-image/build';
import QuestionIcon from 'assets/icon/question-mark.svg';
import PharmacyWidgetDrawer from 'pages/Dashboard/components/GridData/PharmacyWidgetDrawer';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

ChartJS.register(ArcElement, Tooltip, Legend);

const plugin = {
    beforeInit(chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)();
            // Change the height as suggested in another answers
            this.height = this.height + 25;
        };
    },
};

const SavingsWidget = ({dateRange, planId, groupId, startDate, endDate}) => {
    const API = useAxios();
    const [active, setActive] = useState('3');
    const [graphData, setGraphData] = useState(null);
    const [options, setOptions] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [disabledViewButtom, setDisabledViewButtom] = useState(true);
    useEffect(()=>{
        setActive(dateRange);
        getTotalSpendData();
    }
    ,[dateRange, planId, groupId, startDate, endDate]);


    const getTotalSpendData = async()=>{
        const { data } = await API.get(
            `/PBMTenant/${planId}/PBMDashboard/gettotalspend/${groupId}/${dateRange}/${startDate}/${endDate}`);
          if (data) 
          {

            let grpOptions = {
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            color: '#000',
                            size: 15,
                        },
                    },
                    y: {
                        border: {
                            display: false,
                        },
                        ticks: {
                            // include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                if(value>=1000)
                                {
                                    return (
                                        '$' + (value/1000) + 'k'
                                    );
                                }
                                else
                                {
                                    return (
                                        '$' + value
                                    );
                                }
                               
                            }
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            padding: 15,
                            usePointStyle: true,
                            pointStyle: 'rect',
                            color: '#707070',
                            font: {
                                weight: 'bold',
                            },
                        },
                        align: 'start',
                    },
                    tooltip: {
                        enabled: true,
                        position: 'nearest',
                        callbacks: {
                            
                            // title: function (item, index) {
                            //     const { totalSpend } = data[item[0].dataIndex];
                            //     return `NDC# ${ndc}\n${label} \nRx Count ${count}`;
                            // },
                            label: function (d, t) {
                                var xLabel = d.dataset.label;
                                var yLabel = '$' + d.formattedValue;
                                return xLabel + ': ' + yLabel;
                            },
                        },
                        // external: (context) => externalTooltipHandler(context, data),
                    },
                    datalabels: {
                        display: true,
                        align: function (context) {
                            return context.dataset.data[context.dataIndex] < 150000
                                ? 'end'
                                : 'start';
                        },
                        color: function (context) {
                            return context.dataset.data[context.dataIndex] < 150000
                                ? 'black'
                                : 'black';
                        },
                        anchor: 'end',
                        padding: {
                            right: 2,
                        },
                        labels: {
                            padding: { top: 10 },
                            value: {
                                color: '#2B3346',
                            },
                        },
                        formatter: function (value) {
                            return (
                                '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                            );
                        },
                    },
                },
                
            };

            setOptions(grpOptions);

            let grpData = {
                labels: data.labels,
                datasets: [
                    {
                        label: 'Total Spend',
                        data: data.totalSpend,
                        borderColor: '#D5351F',
                        backgroundColor: '#D5351F',
                        pointRadius: 2.5
                    }
                ],
            };

            setGraphData(grpData);
            if(data.totalSpend!==null && data.totalSpend.length>0)
            {
                setDisabledViewButtom(false);
            }
            else
            {
                setDisabledViewButtom(true);
            }

        }

    }

    const handleActive = (title) => {
        setActive(title);
    };
    const getDate = () => {
        setActive('custom')
    }
    const handleProceed = ()=>{
        setIsOpen(!isOpen);
    }
    const onHandleIsOpen = (hasRefresh)=> {
        if (isBoolean(hasRefresh) && hasRefresh) {
            onClose(true);
        }
        setIsOpen(!isOpen)
      }
    return (
        <ErrorBoundary>
            <Card className='p-3 h-100'>
                <CardHeader className='border-0 bg-white px-0 pt-0'>
                    <Row className='align-items-center'>
                        <Col>
                            <CardTitle className='f-20 font-weight-500'>
                                Total Spend by Month
                                <span className='qu_icon' style={{"paddingLeft":"10px"}} data-title={"The total Plan Paid amount for the net processed, non-reversed claims per month."}>
                                    <img src={QuestionIcon} style={{"width":"20px"}} />
                               </span>
                            </CardTitle>
                        </Col>
                        <Col xs='auto' hidden={true}>
                            <Nav className='widget-tabs'>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === '3'
                                                ? 'active-tab cursor-pointer f-12'
                                                : 'cursor-pointer f-12'
                                        }
                                        onClick={() => handleActive('3')}
                                    >
                                        3 Mon
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === '6'
                                                ? 'active-tab cursor-pointer f-12'
                                                : 'f-12 cursor-pointer'
                                        }
                                        onClick={() => handleActive('6')}
                                    >
                                        6 Mon
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === '12'
                                                ? 'active-tab cursor-pointer f-12'
                                                : 'cursor-pointer f-12'
                                        }
                                        onClick={() => handleActive('12')}
                                    >
                                        1 Yr
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={
                                        active === 'custom'
                                            ? 'active-tab cursor-pointer f-12'
                                            : 'cursor-pointer f-12'
                                    }>
                                        <CustomeDate getDate={getDate} active={active} />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className='dashboard-chart-body'>
                    {graphData && (<Line options={options} data={graphData} plugins={[plugin]} />)}
                    <div className="view-report">
                         <Button
                            color='primary'
                            className='ms-2'
                            onClick={handleProceed}
                            style={{ float: "right",marginTop:"26px" }}
                            disabled={disabledViewButtom}
                        >
                            View Report
                    </Button>
                    </div>

                
                    {/* <div className='view-report'>
                        <a
                            href="data:text/csv;charset=utf-8,'+escape(csv)+'"
                            download='filename.csv'
                        >
                            {' '}
                            View Report{' '}
                        </a>
                    </div> */}
                </CardBody>
            </Card>
            <Drawer
                direction='end'
                title='Total Spend by Month'
                isOpen={isOpen}
                toggle={handleProceed}
                size="lg"
            >
                <PharmacyWidgetDrawer dateRange={dateRange} planId={planId} groupId={groupId} startDate={startDate} endDate={endDate} onHandleIsOpen={onHandleIsOpen}  />
            </Drawer> 
        </ErrorBoundary>
    );
};

export default SavingsWidget;
