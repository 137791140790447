import React, { useState, useEffect } from 'react';
import useAxios from 'hooks/useAxios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    CardHeader,
    Button,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { shuffle } from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import QuestionIcon from 'assets/icon/question-mark.svg';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
import ErrorBoundary from 'components/ErrorBoundary';
import CustomeDate from './customeDate';
import Drawer from 'components/Drawer';
import DrugWidgetDrawer from 'pages/Dashboard/components/GridData/DrugWidgetDrawer';
// const pharmacyData = [
//     {
//         Pharmacy: 'Pharmacy 1',
//         CoveredEntity: 'PharmaForce General Hospital 1',
//         RxCount: 4000,
//         Savings: 215124,
//     },
//     {
//         Pharmacy: 'Pharmacy 2',
//         CoveredEntity: 'PharmaForce General Hospital 1',
//         RxCount: 1000,
//         Savings: 101225,
//     },
//     {
//         Pharmacy: 'Pharmacy 3',
//         CoveredEntity: 'PharmaForce General Hospital 1',
//         RxCount: 523,
//         Savings: 85223,
//     },
//     {
//         Pharmacy: 'Pharmacy 4',
//         CoveredEntity: 'PharmaForce General Hospital 1',
//         RxCount: 659,
//         Savings: 62354,
//     },

// ];

const TopDrugs = ({dateRange, planId, groupId, opaCode, startDate,endDate}) => {
    const API = useAxios();
    const [chartData, setChartData] = useState(null);
    const [options, setOptions] = useState(null);
    const [active, setActive] = useState('3');
    const [isOpen, setIsOpen] = useState(false);
    const [disabledViewButtom, setDisabledViewButtom] = useState(true);
    useEffect(()=>{
        setActive(dateRange);
        handleActive(dateRange);
        getDrugData();
    },[dateRange, planId, groupId, startDate, endDate]);


    const getDrugData = async()=>{
        const { data } = await API.get(
            `/PBMTenant/${planId}/PBMDashboard/getspendbydrugs/${groupId}/${dateRange}/${startDate}/${endDate}`);

            let grpOptions = {
                maintainAspectRatio: false,
                indexAxis: 'y',
                barThickness: 22,
                elements: {
                    bar: {
                        borderWidth: 3,

                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            // include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                if(value>1000)
                                {
                                    return (
                                        '$' + (value/1000).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + 'k'
                                    );
                                }
                                else
                                {
                                    return (
                                        '$' + value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                    );
                                }
                               
                            }
                        },
                    },
                    y: {
                        grid: {
                            display: false,
                        },
                    },
                },
                
                responsive: true,
                plugins: {
                    //enabled:true,
                    //position: 'nearest',
                    tooltip: {
                        callbacks: {
                            title: function (item) {
                                const { count, ndc, label } = data[item[0].dataIndex];
                                return `NDC# ${ndc}\n${label} \nRx Count ${count}`;
                            },
                            label: function (d, t) {
                                var xLabel = d.dataset.label;
                                var yLabel = '$' + d.formattedValue;
                                return xLabel + ': ' + yLabel;
                            },
                        },
                    },
                    legend: {
                        display: true,
                    },
                    datalabels: {
                        anchor: 'start',
                        align: 'end',
                        labels: {
                            value: {
                                color: '#2B3346',
                            },
                        },
                        formatter: function (value) {
                            return (
                                '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                            );
                        },
                    },
                },
            };

            setOptions(grpOptions);

        let grpData = {
            labels: data.map(({ label }) => label),
            datasets: [
                {
                    label: '340B Spend',
                    data: data.map(({ saving340B }) => saving340B),
                    borderColor: '#B54DA0',
                    backgroundColor: '#B54DA0',
                },
                {
                    label: 'Total Spend',
                    data: data.map(({ saving }) => saving),
                    borderColor: 'rgb(152, 206, 143, 1)',
                    backgroundColor: 'rgba(152, 206, 143, 1)',
                    //barPercentage : 1.3
                },
            ],
        };
        setChartData(grpData);
        if(data.length>0)
        {
            setDisabledViewButtom(false);
        }
        else
        {
            setDisabledViewButtom(true);
        }
    }

   

    

    const handleActive = (title) => {
        // if (active === title) return;
        // const shuffleArry = shuffle(data);
        setActive(title);
        //setData(shuffleArry);
    };

    const getDate = () => {
        setActive('custom')
    };

    const handleProceed = ()=>{
        setIsOpen(!isOpen);
    }
    const onHandleIsOpen = (hasRefresh)=> {
        if (isBoolean(hasRefresh) && hasRefresh) {
            onClose(true);
        }
        setIsOpen(!isOpen)
      }
    return (
        <ErrorBoundary>
            <Card className='p-3 h-100'>
                <CardHeader className='border-0 bg-white px-0'>
                    <Row className='align-items-center'>
                        <Col>
                            <CardTitle className='f-20 font-weight-500 mb-0'>
                            Spend by Drugs
                            <span className='qu_icon' style={{"paddingLeft":"10px"}} data-title={"Total Spend is the total Plan Paid amount for the net processed, non-reversed claims for the specified timeframe at the drug level, and 340B Spend is the Plan Paid amount for the 340B captured claims for the same timeframe."}>
                                <img src={QuestionIcon} style={{"width":"20px"}} />
                            </span>
                                <p className='dashboard-sub-text'></p>
                            </CardTitle>
                        </Col>
                        <Col xs='auto' hidden={true}>
                            <Nav className='widget-tabs'>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === '3'
                                                ? 'active-tab f-12 cursor-pointer'
                                                : 'f-12 cursor-pointer'
                                        }
                                        onClick={() => handleActive('3')}
                                    >
                                        3 Mon
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === '6'
                                                ? 'active-tab f-12 cursor-pointer'
                                                : 'f-12 cursor-pointer'
                                        }
                                        onClick={() => handleActive('6')}
                                    >
                                        6 Mon
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === '12'
                                                ? 'active-tab f-12 cursor-pointer'
                                                : 'f-12 cursor-pointer'
                                        }
                                        onClick={() => handleActive('12')}
                                    >
                                        1 Yr
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={
                                        active === 'custom'
                                            ? 'active-tab f-12 cursor-pointer'
                                            : 'f-12 cursor-pointer'
                                    }>
                                        <CustomeDate getDate={getDate} active={active} />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className='dashboard-chart-body'>
                    <div className='dashboard-horizontal-combochart-height'>
                        {
                            chartData && (
                                <Bar
                                options={options}
                                data={chartData}
                                plugins={[ChartDataLabels]}
                            />
                            )
                        }
                        <div className="view-report">
                            <Button
                                color='primary'
                                className='ms-2'
                                onClick={handleProceed}
                                style={{ float: "right",marginTop:"26px" }}
                                hidden={(chartData==null)}
                                disabled={disabledViewButtom}
                            >
                                View Report
                        </Button>
                        </div>
                    </div>
                    {/* <div className='view-report'>
                        <a
                            href="data:text/csv;charset=utf-8,'+escape(csv)+'"
                            download='filename.csv'
                        >
                            {' '}
                            View Report{' '}
                        </a>
                    </div> */}
                </CardBody>
            </Card>
            <Drawer
                direction='end'
                title='Spend by Drugs'
                isOpen={isOpen}
                toggle={handleProceed}
                size="lg"
            >
                <DrugWidgetDrawer dateRange={dateRange} planId={planId} groupId={groupId} startDate={startDate} endDate={endDate} onHandleIsOpen={onHandleIsOpen}  />
            </Drawer> 
        </ErrorBoundary>
    );
};

export default TopDrugs;
