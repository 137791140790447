import React, { PureComponent } from "react";
import SpinningLogo from "../../assets/images/SpinningLogo.gif";

let style = {
  opacity: "0.5",
  background: "#000",
  width: "100%",
  height: "100%",
  zIndex: 10,
  top: 0,
  left: 0,
  position: "fixed",
};

const DEFAULT_LOADER_STYLE = {
  width: "auto",
  zIndex: "100",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ABSOLUTELY_POSITIONED_STYLE = {
  position: "absolute",
  top: "calc(50% - 15px)",
  left: "calc(50% - 36px)",
};

class Loader extends PureComponent {
  getLoaderStyle = () => {
    return {
      ...DEFAULT_LOADER_STYLE,
      ...ABSOLUTELY_POSITIONED_STYLE,
    };
  };

  render() {
    const { type, height, width, color, opacity, bgColor } = this.props;
    // const loaderType = type || "spin";
    // const loaderHeight = height || "50px";
    // const loaderWidth = width || "50px";
    // const loaderColor = color || "#fff";
    if (opacity) style = { ...style, opacity };
    if (bgColor) style = { ...style, background: bgColor };
    return (
      <div style={this.getLoaderStyle()}>
        <div style={style}></div>
        <div style={{ zIndex: 99 }} className="bg-white rounded p-1">
          <img src={SpinningLogo} alt="CPS" height={50} />
        </div>
      </div>
    );
  }
}

export default Loader;
