import React, { useState, useEffect } from 'react';
import useAxios from 'hooks/useAxios';
import { Button, Card, CardBody, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import { Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import PharmacyWidgetSummary from 'pages/Dashboard/components/GridData/PharmacyWidgetSummary';
import ClaimWidgetDetails from 'pages/Dashboard/components/GridData/ClaimWidgetDetails';
const PharmacyWidgetDrawer =(props)=>{
    const {dateRange, planId, groupId, startDate, endDate, onHandleIsOpen} = props;
    const [activeTab, setActiveTab] = useState('1');
    const [initialFilters, setInitialFilters] = useState({
      currentPage: 1,
      pageSize: 10,
      orderBy: '',
      order: '',
      groupId:groupId,
      dateRange : dateRange,
      startDate : startDate,
      endDate : endDate,
      filters: [],
    });
    const handleBack = ()=>{
        onHandleIsOpen();
      }
      const toggle = (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
        }
      };
      

      return (
        <div>
          <Nav tabs className='border-0 px-3 py-0'>
            <NavItem>
              <NavLink
                to=""
                className={classNames({
                  activeState: activeTab === '1',
                })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Summary Report
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to=""		  
                className={classNames({
                  activeState: activeTab === '2',
                })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Detailed Report
              </NavLink>
            </NavItem>
          </Nav>
          <hr className='mb-0 mt-2' />
          <TabContent activeTab={activeTab} className='px-3 mt-0 pt-0'>
            <TabPane tabId='1' >
                <ErrorBoundary>
                    <PharmacyWidgetSummary dateRange={dateRange} planId={planId} groupId={groupId} filter={initialFilters} />
                </ErrorBoundary>
              {/* {hasHistoricalClaims && (
                <ErrorBoundary>
                  <HistoricalClaims {...props} />
                </ErrorBoundary>
              )} */}
            </TabPane>
            <TabPane tabId='2'>
                <ErrorBoundary>
                    <ClaimWidgetDetails dateRange={dateRange} planId={planId} groupId={groupId} filter={initialFilters} />
                </ErrorBoundary>
              {/* {hasCloseMatch && (
                <ErrorBoundary>
                  <CloseMatch {...props} />
                </ErrorBoundary>
              )} */}
            </TabPane>
          </TabContent>
        </div>
      );
}

export default PharmacyWidgetDrawer;