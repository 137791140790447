import React, { useState, useEffect } from 'react';
import useAxios from 'hooks/useAxios';
import ReactStrapTable from 'components/reactStrapTable';
import {PharmacySummaryColumns} from 'utils/columns';
import Loader from 'components/Loader';
import { onExport } from 'utils/helper';
const PharmacyWidgetSummary = (props)=>{
    const API = useAxios();
    const {dateRange, planId, groupId, filter} = props;
    const [summaryData, setSummaryData] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [totalSize, setTotalSize] = useState(0);

    const [state, updateState] = useState({
        filters: filter,
      });

      const setState = (state) => updateState((pre) => ({ ...pre, ...state }));

    useEffect(()=>{
        getClaimWidgetSummaryData(state.filters);
    },[]);

    const getClaimWidgetSummaryData = async(filters)=>{
        setSubmitting(true);
        try{
            const {data}= await API.post(
                `/PBMTenant/${planId}/PBMDashboard/getprocessedclaimssummary`, filters);
                if(data)
                {
                    setSummaryData(data.items);
                    setTotalSize(data.totalRecords);
                    setSubmitting(false);
                }
        }
        catch(ex)
        {
            setSubmitting(false);
        }
        
    };
    const { filters: colFilter } = state;

    const onPageChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
          setState({
            filters: filter,
          });
          let colFilterOn = { ...filter, filters: colChangedFilter };
          getClaimWidgetSummaryData();
        } else {
         var pageFilter = state.filters;
         pageFilter = {...state.filters, ...filter};
          setState({
            filters: pageFilter,
          });
          getClaimWidgetSummaryData(pageFilter);
        }
      };

      const handleExport = async () => {
        const { access_token } = JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
        const action = `${process.env.REACT_APP_PBMCONFIG_ENDPOINT}/PBMTenant/${planId}/PBMDashboard/exportspendbypharmacysummary?access_token=${access_token}`;
        onExport(action, state.filters);
      };
    

    return (
        <div
            className='table-card scroll-table position-relative border-0 rounded pt-0'
            fixed-height={summaryData.length > 0 ? 'fixed-height' : 'default'}
        >
            {submitting && <Loader opacity={0.01} color='black' />}
            <ReactStrapTable
                id='invoicedetail-table'
                fixedColumn
                data={summaryData}
                columns={PharmacySummaryColumns}
                onPageChange={onPageChange}
                onPageSizeChange={onPageChange}
                hasPagination
                pageSize={colFilter.pageSize}
                currentPage={colFilter.currentPage}
                totalRecords={totalSize}
                hasFilterColumn={false}
                onExport={handleExport}
                gridMsg="No Data"
            />
        </div>
    );
}

export default PharmacyWidgetSummary;