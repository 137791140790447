import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-toastify/dist/ReactToastify.css';
// import './style/app.scss';


// const LightTheme = React.lazy(() => import('themes/PFTheme.js'));

// let TYPE_OF_THEME = "PF"
// const ThemeSelector = () => {
//     //const CHOSEN_THEME = localStorage.getItem('TYPE_OF_THEME') || TYPE_OF_THEME;
//     return (
//         <>
//             <React.Suspense fallback={<></>}>
//                 <LightTheme />
//             </React.Suspense>
//         </>
//     )
// }

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <App />
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
