import React, { useEffect } from 'react';
import Loader from 'components/Loader';
import Logo from 'components/Logo';
import useOidc from 'hooks/useOidc';

const OidcLogin = () => {
  const { signIn, signinRedirectCallback } = useOidc();
  const { hash } = new URL(window.location.href);

  useEffect(() => {
    signinRedirectCallback();
    const hasLoginStarted = sessionStorage.getItem('hasLoginStarted');
    if (hasLoginStarted) {
      setTimeout(() => {
        signIn();
      }, 5000);
    } else {
      sessionStorage.setItem('hasLoginStarted', true);
      signIn();
    }
  });

  return (
    <div className='h-90vh d-flex align-items-center justify-content-center'>
      <div className='text-center'>
        <Logo />
        <p className='text-primary'>
          {hash ? 'Fetching Details...' : 'Loading...'}
        </p>
        <Loader opacity={0.4} color='black' />
      </div>
    </div>
  );
};

export default OidcLogin;
