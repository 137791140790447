import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Table, Input, Button, ButtonGroup } from 'reactstrap';
import ColumnFilters from './components/ColumnFilters';
import Loader from 'components/Loader';
import crossIcon from 'assets/icon/cross.png';
import searchIcon from 'assets/icon/search.png';
import leftArrowIcon from 'assets/icon/cps-left-arrow.svg';
import rightArrowIcon from 'assets/icon/cps-right-arrow.svg';
import Pagination from 'components/Pagination';
import { find } from 'lodash';

let keysName = [];
let columnChangedFilters = [];
let isHaveColFilter = false;

const ReactStrapTable = ({
  id,
  columns,
  data,
  fixedColumn,
  handleSort = () => '',
  loading,
  reset,
  loader,
  hasScrollArrow = true,
  onFilterCancel = () => '',
  onSearchColumnFilters = () => '',
  pageSize,
  onPageSizeChange = () => '',
  children,
  onExport,
  hasPagination,
  onPageChange,
  currentPage,
  totalRecords,
  filters,
  researchNeededCount = 0,
  eligibleCount = 0,
  inEligibleCount = 0,
  hasNoEligibility = true,
  hasFilterColumn=true,
  gridMsg = 'No record found'
}) => {
  const [key, setKey] = useState('');
  const [resetField, setResetField] = useState(false);
  const [hasVisibleScrollArrow, setVisibleScrollArrow] = useState(false);
  const [hasLeftArrow, setLeftArrow] = useState(false);
  const [hasRightArrow, setRightArrow] = useState(false);
  const [rSelected, setRSelected] = useState('off');
  const [colFilterEnable, setColFilterEnable] = useState(false);
  const [sortKey, setSortKey] = useState('desc');

  const selectRef = useRef(null);

  // useEffect(() => {
  //   columnChangedFilters = [];
  // }, []);
  const getUpdateColum = () => {
    const updateCol = [];
    const col = columns[0];
    if (!col?.action && colFilterEnable) {
      updateCol.push({
        dataField: 'Options',
        text: 'Options',
        width: 80,
        show: true,
        disabled: true,
        hideColFilter: true,
        action: true,
      });
    }
    return [...updateCol, ...columns];
  };
  useEffect(() => {
    setKey('');
  }, [reset]);

  const removeSort = (keys) => {
    keysName.push(keys);
    const filter = keysName.filter((item) => item === keys);
    if (filter.length === 3) {
      setKey('');
      setSortKey('desc');
      keysName = [];
    } else {
      const sortObj = {
        order: sortKey === 'desc' ? 'asc' : 'desc',
        orderBy: keys,
      };
      setSortKey(sortObj.order);
      if (isHaveColFilter) {
        const apiFilter = {
          ...filters,
          ...sortObj,
          filters: [...columnChangedFilters],
        };
        // handleSort(filters, keys === "claimStatus" ? "claimID" : keys, true, columnChangedFilters);
        handleSort(apiFilter, true);
      } else {
        const apiFilter = { ...filters, ...sortObj };
        // handleSort(filters, keys === "claimStatus" ? "claimID" : keys);
        handleSort(apiFilter);
      }
    }
  };

  const onColHeaderClick = (col) => {
    if (col.disabled) return;
    setKey(col.dataField);
    removeSort(col.dataField);
  };

  const headerRenderer = () => {
    const cols = getUpdateColum().map((col, index) => {
      return (
        <th
          key={col.dataField}
          className={
            key === col.dataField
              ? `active-sorting-${sortKey === 'asc' ? 'down' : 'up'}`
              : ''
          }
          width={col.width || 100}
          onClick={() => {
            col.sort ? onColHeaderClick(col) : '';
          }}
        >
          {col.text}
        </th>
      );
    });
    return (
      <thead key='headerrow'>
        <tr>
          {/* {colFilterEnable && columns[0].action ? <th width={100}>Options</th> : ""} */}
          {cols}
          {/* {colFilterEnable && <th width={100}>Options</th>} */}
        </tr>
      </thead>
    );
  };
  const renderRow = () => {
    if (data.length) {
      const rows = data.map((row, index) => {
        const rowItem = getUpdateColum().map((col) => {
          // if (index === 0 && colFilterEnable) {
          //   return <th key="option" width={100}></th>;
          // }
          if (col.cellRenderer) {
            return (
              <td
                key={col.dataField}
                width={col.width || 100}
                className={col.edit ? 'py-1' : col.className || ''}
              >
                {col.cellRenderer(row, col)}
              </td>
            );
          }
          return (
            <td key={col.dataField} width={col.width || 100}>
              {row[col.dataField]}
            </td>
          );
        });
        return (
          <tr key={index}>
            {rowItem}
            {/* {colFilterEnable && <td width={100}></td>} */}
          </tr>
        );
      });
      return rows;
    } else {
      return (
        <tr>
          <td colSpan={getUpdateColum().length} className='text-center'>
            {loading ? 'Loading...' : gridMsg}
          </td>
        </tr>
      );
    }
  };

  const handleColumnFilterChange = ({ target: { value } }, col, type, filterType) => {
    const { dataField } = col;
    const obj = {
      filterName: dataField,
      filterType: filterType === "" ? 1 : filterType,
    };
    if (!value) {
      const filter = columnChangedFilters?.filter(
        (item) => item.filterName !== dataField
      );
      columnChangedFilters = filter;
      return;
    }
    const findObj = find(columnChangedFilters, {
      filterName: dataField,
    });
    if (findObj) {
      if (type === 'field') {
        findObj.filterValue = value;
      } else {
        findObj.filterType = parseInt(value);
      }
    } else {
      if (type === 'field') {
        obj.filterValue = value;
      } else {
        obj.filterType = parseInt(value);
      }
      const colFilters = [...columnChangedFilters, ...[obj]].filter(
        (item) => item.filterValue
      );
      columnChangedFilters = colFilters;
    }
  };

  const onSearchFilter = () => {
    isHaveColFilter = true;
    onSearchColumnFilters(columnChangedFilters);
  };

  const onCancelFilter = () => {
    isHaveColFilter = false;
    onFilterCancel();
    setResetField((pre) => !pre);
    columnChangedFilters = [];
  };

  const columnFilters = () => {
    return (
      <tr style={{ position: "sticky", top: "50px", "zIndex": 11 }}>
        {getUpdateColum()?.map((col, index) => {
          if (index === 0) {
            return (
              <td key='action'>
                <span className='pe-2 cursor-pointer' onClick={onSearchFilter}>
                  <img src={searchIcon} alt='' />
                </span>
                <span className='cursor-pointer' onClick={onCancelFilter}>
                  <img src={crossIcon} alt='' />
                </span>
              </td>
            );
          }
          if (col.hideColFilter) {
            return <td key={col.dataField}></td>;
          }
          return (
            <td key={col.dataField}>
              <ColumnFilters
                col={col}
                onColumnFilterChange={handleColumnFilterChange}
                reset={resetField}
              />
            </td>
          );
        })}
      </tr>
    );
  };

  const getCurrentTableElement = () =>
    document
      .querySelector(`#${id}`)
      .getElementsByClassName('table-responsive')[0];

  const handleMouseEnter = () => {
    if (!id) return;
    const element = getCurrentTableElement();
    const rightScroll =
      element.scrollWidth - element.scrollLeft - element.clientWidth;
    if (rightScroll <= 10 && element.scrollLeft <= 10) return;
    if (element.scrollLeft <= 0) {
      setLeftArrow(false);
      setRightArrow(true);
    } else {
      setLeftArrow(true);
    }
    if (rightScroll <= 0) {
      setLeftArrow(true);
      setRightArrow(false);
    }
    setVisibleScrollArrow(true);
  };

  const handleMouseLeave = () => {
    setVisibleScrollArrow(false);
  };

  const scroll = (direction) => {
    const element = getCurrentTableElement();
    slideScroll(element, direction, 50, 500, 70);
  };

  const slideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      element.behavior = 'smooth';
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);

    setTimeout(() => {
      const el = getCurrentTableElement();
      const rightScroll = el.scrollWidth - el.scrollLeft - el.clientWidth;
      if (el.scrollLeft === 0) {
        setLeftArrow(false);
      } else {
        setLeftArrow(true);
      }
      if (rightScroll <= 0) {
        setRightArrow(false);
      } else {
        setRightArrow(true);
      }
    }, 500);
  };

  const renderScrollArrowButtons = () => {
    if (hasScrollArrow && hasVisibleScrollArrow && data && data.length) {
      return (
        <>
          {hasLeftArrow && (
            <Button
              onClick={() => scroll('left')}
              color='link'
              className={`left-table-button ${!hasLeftArrow ? 'opacity-25' : ''
                }`}
              disabled={!hasLeftArrow}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={leftArrowIcon}
                alt='leftArrowIcon'
                height={32}
                width={32}
              />
            </Button>
          )}
          {hasRightArrow && (
            <Button
              onClick={() => scroll('right')}
              color='link'
              className={`right-table-button ${!hasRightArrow ? 'opacity-25' : ''
                }`}
              disabled={!hasRightArrow}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={rightArrowIcon}
                alt='rightArrowIcon'
                height={32}
                width={32}
              />
            </Button>
          )}
        </>
      );
    }
    return null;
  };

  const onClickFilter = (value) => {
    if (value === 'on') {
      setRSelected(value);
      setColFilterEnable(true);
    } else {
      setRSelected(value);
      setColFilterEnable(false);
    }
    columnChangedFilters = [];
  };

  const onHandlePageChange = (page) => {
    const pageFilter = { ...filters, currentPage: page };
    if (isHaveColFilter) {
      let colFilterOn = { ...pageFilter, filters: columnFilters };
      return onPageChange(colFilterOn, true, columnChangedFilters);
    }
    onPageChange(pageFilter);
  };

  const handlePageSizeChange = (e) => {
    const { value } = e.target;
    const pageFilter = {
      ...filters,
      currentPage: 1,
      pageSize: parseInt(value),
    };
    if (isHaveColFilter) {
      let colFilterOn = { ...pageFilter, filters: columnFilters };
      return onPageSizeChange(colFilterOn, true, columnChangedFilters);
    }
    onPageSizeChange(pageFilter);
  };

  return (
    <>
      {pageSize && (
        <Row className='justify-content-end py-2'>
          <Col>
            <span className='text-dark f-14'>Display</span>
            <Input
              type='select'
              onChange={handlePageSizeChange}
              bsSize='sm'
              className='display-select d-inline-block'
              value={pageSize}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Input>
            <span className='text-dark f-14 d-inline-block'>records</span>
          </Col>
          <Col xs='auto'>
            {onExport && (
              <Button
                size='sm'
                color='export'
                className='ms-auto'
                onClick={onExport}
                disabled={!data.length}
              >
                Export
              </Button>
            )}
            {
              hasFilterColumn && (
                <div>
                  <span className='ps-3 pe-2 f-13'>Filter Column</span>
                  <ButtonGroup className='filter-btn-group'>
                    <Button
                      color='info'
                      outline
                      onClick={() => onClickFilter('off')}
                      active={rSelected === 'off'}
                      disabled={!data.length}
                    >
                      OFF
                    </Button>
                    <Button
                      color='info'
                      outline
                      onClick={() => onClickFilter('on')}
                      active={rSelected === 'on'}
                      disabled={!data.length}
                    >
                      On
                    </Button>
                  </ButtonGroup>
                </div>
              )
            }
            
          </Col>
        </Row>
      )}
      <div
        className='rounded-table'
        ref={selectRef}
        id={id}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseEnter}
      >
        {!loader && loading && <Loader opacity={0.01} color='black' />}
        {renderScrollArrowButtons()}
        <Table
          responsive
          id='reactstrap-table'
          className={`table table-striped theme-table ${fixedColumn && 'fixed-column-table'
            }`}
          style={{ scrollBehavior: 'smooth' }}
        >
          {headerRenderer()}
          <tbody>
            {colFilterEnable && columnFilters()}
            {renderRow()}
          </tbody>
        </Table>
      </div>
      {hasPagination && (
        <Pagination
          onPageChange={onHandlePageChange}
          currentPage={currentPage}
          totalRecords={totalRecords}
          pageSize={pageSize}
          hasNoEligibility={hasNoEligibility}
          researchNeededCount={researchNeededCount}
          eligibleCount={eligibleCount}
          inEligibleCount={inEligibleCount}
        />
      )}
    </>
  );
};

export default ReactStrapTable;
