import React from 'react';
import Layout from 'containers/Layout';
import OidcLogin from 'components/OidcLogin';

export const PrivateRoute = ({ component, modules, ...rest }) => {
  const { token_type } =
    JSON.parse(sessionStorage.getItem('pharmaforce')) || {};

  if (token_type) return <Layout modules={modules} />;
  return <OidcLogin />;
};
