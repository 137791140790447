import React from 'react';
import moment from 'moment';

export const cellRendererCheck = (value) => {
  if (!value) return '-';
  return value;
};


export const cellRendererToolTip = (row) => {
  if (!row.faxStatus) return '-';
  return (<span title={row.faxStatusToolTip}>{row.faxStatus}</span>);
}

export const currencyFormat = (value) => {
  if (!value || isNaN(value)) return '-';
  if (value < 0)
    return (
      <span className='text-danger text-end'>
        {'-$' +
          (value * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
  else
    return (
      <span className='text-end'>
        {'$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
};

export const fileFormatter = (row, download) => {
  const { files } = row;
  if (files && files.length) {
    return files.map((file, index) => {
      return (
        <span
          className='fax-link'
          key={`${file.fileName}${index}`}
          onClick={() => download(file)}
        >
          {file.fileName}
          <br />
        </span>
      );
    });
  }
  return <span>There is no attachment</span>;
};

export const dateFormate = (value) => {
  if (!value) return '-';
  return moment(new Date(value)).format('MM/DD/YYYY');
};

export const dateTimeFormate = (value) => {
  if (!value) return '-';

  var dt = moment(new Date(value)).format('MM/DD/YYYY hh:mm A');
  var strDt = new Date(dt+' UTC');
  return moment(new Date(strDt)).format('MM/DD/YYYY hh:mm A');
};

const userColumns = [
  {
    dataField: 'firstName',
    text: 'First Name',
    cellRenderer: (rowData) => cellRendererCheck(rowData.firstName),
    sort: true,
    width: 144,
    show: true,
    inputType: 'text',
  },
  {
    dataField: 'lastName',
    text: 'Last Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.lastName),
  },
  {
    dataField: 'userName',
    text: 'User Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.email),
  },
  {
    dataField: 'phoneNumber',
    text: 'Phone Number',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.phoneNumber),
  },
  {
    dataField: 'appAssigned',
    text: 'App. Assigned',
    sort: false,
    width: 130,
    show: true,
    edit: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.appAssigned),
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: false,
    width: 150,
    show: true,
    edit: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.status),
  },
  {
    dataField: 'modifiedDate',
    text: 'Modified Date',
    sort: true,
    width: 90,
    show: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.modifiedDate),
  },
  {
    dataField: 'modifiedBy',
    text: 'Modified By',
    sort: false,
    width: 90,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.modifiedBy),
  },
];

export default userColumns;


export const SummaryColumns = [
  {
    dataField: 'monthName',
    text: 'Month',
    sort: false,
    width: 80,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.monthName),
  },
  {
    dataField: 'pharmacy',
    text: 'Pharmacy',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacy),
  },
  {
    dataField: 'pharmacyNPI',
    text: 'Pharmacy NPI',
    sort: false,
    width: 100,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyNPI),
  },
  {
    dataField: 'claim340BCount',
    text: '340B Claim Count',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.claim340BCount),
  },
  {
    dataField: 'claimNon340BCount',
    text: 'Non 340B Claim Count',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.claimNon340BCount),
  },
  {
    dataField: 'totalClaimCount',
    text: 'Total Claim Count',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.totalClaimCount),
  },
];

export const PharmacySummaryColumns = [
  {
    dataField: 'monthName',
    text: 'Month',
    sort: false,
    width: 80,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.monthName),
  },
  {
    dataField: 'pharmacy',
    text: 'Pharmacy',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacy),
  },
  {
    dataField: 'pharmacyNPI',
    text: 'Pharmacy NPI',
    sort: false,
    width: 100,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyNPI),
  },
  {
    dataField: 'claim340BAmount',
    text: '340B Claim Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.claim340BAmount),
  },
  {
    dataField: 'claimNon340BAmount',
    text: 'Non 340B Claim Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.claimNon340BAmount),
  },
  {
    dataField: 'totalClaimAmount',
    text: 'Total Claim Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalClaimAmount),
  },
];

export const ClaimWidgetDetailsColumns = [
  {
    dataField: 'pharmacyNPI',
    text: 'Pharmacy NPI',
    sort: false,
    width: 80,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyNPI),
  },
  {
    dataField: 'pharmacy',
    text: 'Pharmacy Name ',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacy),
  },
  {
    dataField: 'pharmacyAddress',
    text: 'Pharmacy Address',
    sort: false,
    width: 100,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyAddress),
  },
  {
    dataField: 'bin',
    text: 'BIN',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.bin),
  },
  {
    dataField: 'processorControlNumber',
    text: 'PCN',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.processorControlNumber),
  },
  {
    dataField: 'groupId',
    text: 'GroupId',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.groupId),
  },
  {
    dataField: 'patientRelationshipCode',
    text: 'Patient Relationship Code',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.patientRelationshipCode),
  },
  {
    dataField: 'prescriberId',
    text: 'Prescriber NPI',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.prescriberId),
  },
  {
    dataField: 'prescriberFirstName',
    text: 'Prescriber First Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.prescriberFirstName),
  },
  {
    dataField: 'prescriberLastName',
    text: 'Prescriber Last Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.prescriberLastName),
  },
  {
    dataField: 'datePrescWritten',
    text: 'Date Presc Written',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.datePrescWritten),
  },
  {
    dataField: 'dateOfService',
    text: 'Date Of Service',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.dateOfService),
  },
  {
    dataField: 'rxNumber',
    text: 'RxNumber',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.rxNumber),
  },
  {
    dataField: 'fillNumber',
    text: 'Fill Number',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.fillNumber),
  },
  {
    dataField: 'ndc',
    text: 'NDC',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.ndc),
  },
  {
    dataField: 'drugName',
    text: 'Drug Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.drugName),
  },
  {
    dataField: 'drugManufacturer',
    text: 'Drug Manufacturer',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.drugManufacturer),
  },
  {
    dataField: 'drugClass',
    text: 'Drug Class',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.drugClass),
  },
  {
    dataField: 'brandGenericFlag',
    text: 'Brand Generic',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.brandGenericFlag),
  },
  {
    dataField: 'quantityDispensed',
    text: 'Quantity Dispensed',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.quantityDispensed),
  },
  {
    dataField: 'daysSupply',
    text: 'Days Supply',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.daysSupply),
  },
  {
    dataField: 'compoundCode',
    text: 'Compound Code',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.compoundCode),
  },
  {
    dataField: 'prodSelectionCode',
    text: 'Prod Selection Code',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.prodSelectionCode),
  },
  {
    dataField: 'priorAuthNumSubmitted',
    text: 'Prior AuthNum Submitted',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.priorAuthNumSubmitted),
  },
  {
    dataField: 'planPayAmount',
    text: 'Plan Pay Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.planPayAmount),
  },
  {
    dataField: 'drug340BCost',
    text: '340B Drug Cost',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.drug340BCost),
  },
  {
    dataField: 'profitLoss',
    text: 'Profit Loss',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.profitLoss),
  },
  {
    dataField: 'retailDrugCost',
    text: 'Retail Drug Cost',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.retailDrugCost),
  },
  {
    dataField: 'indicator340B',
    text: 'Indicator 340B',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.indicator340B),
  },
  {
    dataField: 'falloutReason',
    text: 'Fallout Reason',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.falloutReason),
  },
];

export const DrugSummaryColumns = [
  {
    dataField: 'monthName',
    text: 'Month',
    sort: false,
    width: 80,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.monthName),
  },
  {
    dataField: 'prodId',
    text: 'NDC',
    sort: false,
    width: 100,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.prodId),
  },
  {
    dataField: 'drugName',
    text: 'Drug Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.drugName),
  },
  {
    dataField: 'claim340BCount',
    text: '340B Claim Count',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.claim340BCount),
  },
  {
    dataField: 'spend340BAmount',
    text: '340B Spend',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.spend340BAmount),
  },
  {
    dataField: 'claimNon340BCount',
    text: 'Non 340B Claim Count',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.claimNon340BCount),
  },
  {
    dataField: 'spendNon340BAmount',
    text: 'Non 340B Spend',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.spendNon340BAmount),
  },
  {
    dataField: 'totalClaimCount',
    text: 'Total Claim Count',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.totalClaimCount),
  },
  {
    dataField: 'totalClaimAmount',
    text: 'Total Claim Spend',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalClaimAmount),
  },
];

export const PrescriberSummaryColumns = [
  {
    dataField: 'monthName',
    text: 'Month',
    sort: false,
    width: 80,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.monthName),
  },
  {
    dataField: 'providerName',
    text: 'Prescriber Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerName),
  },
  {
    dataField: 'providerNPI',
    text: 'Prescriber NPI',
    sort: false,
    width: 100,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerNPI),
  },
  {
    dataField: 'claim340BAmount',
    text: '340B Claim Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.claim340BAmount),
  },
  {
    dataField: 'claimNon340BAmount',
    text: 'Non 340B Claim Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.claimNon340BAmount),
  },
  {
    dataField: 'totalPaidAmount',
    text: 'Total Claim Amount',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalPaidAmount),
  },
];

export const Non340BVs340Columns = [
  {
    dataField: 'monthName',
    text: 'Month',
    sort: false,
    width: 80,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.monthName),
  },
  {
    dataField: 'totalSpend',
    text: 'Total Spend',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalSpend),
  },
  {
    dataField: 'saving340B',
    text: '340B Saving',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.saving340B),
  },
  {
    dataField: 'totalSpendAfter304B',
    text: 'Total Spend after 340B savings ',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalSpendAfter304B),
  }
];