import React, { useState, useEffect } from "react";
import { includes } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavItem } from "reactstrap";
import ClaimIcon from "assets/icon/claim-icon.svg";
import useAxios from 'hooks/useAxios';
import { Alert } from "bootstrap";

const Subheader = (props) => {
  const { modules } = props;

  const { pathname } = useLocation();

  const hasModule = (code) => {
    return modules.length > 0 && modules.filter(m => m.code == code).length > 0;
  }

  const renderDashboard = () => {
      return (
        <NavItem>
          <Link
            to="/dashboard"
            className={`nav-link ${includes(pathname, "/dashboard") ? "active" : ""
              }`}
          >
            <img src={ClaimIcon} alt="" className="me-2" />
            <span>Dashboard</span>
          </Link>
        </NavItem>
      )
  }
 
  return (
    <Navbar color="dark" dark className="app-subheader">
      <Nav>
        {renderDashboard()}
      </Nav>
    </Navbar>
  );
};

export default Subheader;
