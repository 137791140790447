import { cloneDeep } from 'lodash';

const defaultClaimsFilter = {
  currentPage: 1,
  pageSize: 10,
  order: 'desc',
  orderBy: 'ceReceivable',
  filters: [],
};

const defaultStates = {
  claimsManager: {
    hasFilterd: false,
    search: cloneDeep(defaultClaimsFilter),
    pharmacies: [],
    patients: [],
    claimStatus: {},
    coveredEntity: {},
    pharmacy: {},
    searchPatientTerm: '',
    patient: '',
    provider: '',
    searchTerms: {
      coveredEntityID: '',
      pharmacyID: '',
      patientID: '',
      rxNumber: '',
      providerID: '',
      providerFirstName: '',
      startDate: '',
      endDate: '',
      claimStatusID: '',
    },
  },
};

const initialStates = {
  loading: false,
  claimsManager: cloneDeep(defaultStates.claimsManager),
};

export { initialStates, defaultStates };
