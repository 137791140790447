import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import ErrorBoundary from "components/ErrorBoundary";
import moment from "moment";

const CustomeDate = ({ getDate, active }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [isDateSelected, setIsDateSelected] = useState(false);
    const [startDate, endDate] = dateRange;

    const calenderRef = useRef();

    useEffect(() => {
        if (dateRange.length === 2 && moment(dateRange[1]).isValid()) {
            setIsDateSelected(true);
            getDate();
        } else {
            setIsDateSelected(false);
        }
    }, [dateRange]);

    useEffect(() => {
        if (active !== 'custom') {
            setDateRange([null, null])
        }
    }, [active]);

    const openDatepicker = (e) => {
        calenderRef.current.setOpen(true);
    };

    const LabelInput = ({ onClick }) => {
        return (
            <div>
                <span onClick={onClick}>
                    {isDateSelected
                        ? `${moment(dateRange[0]).format("MMM/YYYY")}-${moment(
                            dateRange[1]
                        ).format("MMM/YYYY")}`
                        : "Custom Range"}
                </span>
            </div>
        );
    };

    return (
        <ErrorBoundary>
            <div className="dashboard-date-rangepicker">
                <DatePicker
                    ref={calenderRef}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    isClearable={true}
                    customInput={<LabelInput onClick={openDatepicker} />}
                    popperPlacement="bottom-end"
                    popperProps={{
                        positionFixed: true,
                    }}
                />
            </div>
        </ErrorBoundary>
    );
};

export default CustomeDate;
