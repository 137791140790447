import React from "react";

const NotValidTab = () => {
  return (
    <div className="text-center pt-4">
      <h6>You do not have permission to access this page.</h6>
    </div>
  );
};

export default NotValidTab;
