import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  loading: false,
  hasDrawer: false,
  claimFilters: '',
  claimList: {
    data: [],
    records: 0,
    currentPage: 1,
    pageSize: 10
  },
  claimListTenantID: ''
};

const StateStore = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        gloabalStates: state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalContext = createContext(initialState);
export default StateStore;
