import React, { useState, useEffect } from 'react';
import useAxios from 'hooks/useAxios';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    CardHeader,
    Nav,
    NavItem,
    Button
} from 'reactstrap';
import ErrorBoundary from 'components/ErrorBoundary';
import { NavLink } from 'react-router-dom';
import ClaimWidgetDrawer from 'pages/Dashboard/components/GridData/ClaimWidgetDrawer';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import CustomeDate from './Widgets/customeDate';
import Drawer from 'components/Drawer';
import { escapeRegExp } from 'lodash';
import HoverImage from 'react-hover-image/build';
import QuestionIcon from 'assets/icon/question-mark.svg';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);


ChartJS.register(ArcElement, Tooltip, Legend);

const plugin = {
    beforeInit(chart) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)();
            // Change the height as suggested in another answers
            this.height = this.height + 25;
            this.width += 120;
        };
    },
};

const ClaimsWidget = ({dateRange, planId, groupId, startDate,endDate}) => {
    const [active, setActive] = useState('3');
    const API = useAxios();
    const [graphData, setGraphData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [disabledViewButtom, setDisabledViewButtom] = useState(true);
    useEffect(()=>{
        setActive(dateRange);
        getClaimWidgtData();
    }
    ,[dateRange, planId, groupId,startDate,endDate]);


    const getClaimWidgtData = async()=>{
        const { data } = await API.get(
            `/PBMTenant/${planId}/PBMDashboard/getprocessedclaims/${groupId}/${dateRange}/${startDate}/${endDate}`);
          if (data) {
            let grpData = {
                labels: data.labels,
                datasets: [
                    {
                        label: 'Claim Count',
                        data: data.totalClaimCount,
                        borderColor: '#12545E',
                        backgroundColor: '#12545E',
                        pointRadius: 2.5
                    },
                    {
                        label: '340B Claim',
                        data: data.total340BClaimCount,
                        borderColor: '#B54DA0',
                        backgroundColor: '#B54DA0',
                        pointRadius: 2.5
                    },
                    {
                        label: 'Non 340B Claim',
                        data: data.totalNon340BClaimCount,
                        borderColor: '#e74c3c',
                        backgroundColor: '#e74c3c',
                        pointRadius: 2.5
                    },
                ],
            };
            setGraphData(grpData);
            if(data.totalClaimCount!==null && (data.totalClaimCount.length>0 || data.total340BClaimCount.length>0 || data.totalNon340BClaimCount.length>0))
            {
                setDisabledViewButtom(false);
            }
            else
            {
                setDisabledViewButtom(true);
            }
          }
          else
          {
            setDisabledViewButtom(true);
          }

    }

    const handleActive = (title) => {
        setActive(title);
    };

    const getDate = () => {
        // if(active === "3") return setActive("6");
        // if(active === "6") return setActive("12");
        // if(active === "12") return setActive("3");
        setActive('custom');
    }
    const handleProceed = ()=>{
        setIsOpen(!isOpen);
    }
    const onHandleIsOpen = (hasRefresh)=> {
        if (isBoolean(hasRefresh) && hasRefresh) {
            onClose(true);
        }
        setIsOpen(!isOpen)
      }
    return (
        <ErrorBoundary>
            <Card className='p-3 h-100'>
                <CardHeader className='border-0 bg-white px-0 pt-0'>
                    <Row className='align-items-center'>
                        <Col>
                            <CardTitle className="f-20 font-weight-500">
                               Net Processed Claims by Month 
                               <span className='qu_icon' style={{"paddingLeft":"10px"}} data-title={"The number of net processed, non-reversed claims per month."}>
                                    <img src={QuestionIcon} style={{"width":"20px"}} />
                               </span>
                            </CardTitle>
                        </Col>
                        <Col xs='auto' hidden={true}>
                            <Nav className='widget-tabs'>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === "3"
                                                ? "active-tab f-12 cursor-pointer"
                                                : "f-12 cursor-pointer"
                                        }
                                        onClick={() => handleActive("3")}
                                    >
                                        3 Mon
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === "6"
                                                ? "active-tab f-12 cursor-pointer"
                                                : "f-12 cursor-pointer"
                                        }
                                        onClick={() => handleActive("6")}
                                    >
                                        6 Mon
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={
                                            active === "12"
                                                ? "active-tab f-12 cursor-pointer"
                                                : "f-12 cursor-pointer"
                                        }
                                        onClick={() => handleActive("12")}
                                    >
                                        1 Yr
                                    </NavLink>
                                </NavItem>
                                <NavItem className='inline-block'>
                                    <NavLink className={
                                        active === "custom"
                                            ? "active-tab f-12 cursor-pointer"
                                            : "f-12 cursor-pointer"
                                    }>
                                        <CustomeDate getDate={getDate} active={active} />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className='dashboard-chart-body'>
                {graphData && (<Line data={graphData} plugins={[plugin]} />)} 
                
                    <div className="view-report">
                         <Button
                            color='primary'
                            className='ms-2'
                            onClick={handleProceed}
                            style={{ float: "right",marginTop:"26px" }}
                            disabled={disabledViewButtom}
                        >
                            View Report
                    </Button>
                    </div>

                
                    {/* <div className="view-report">
                        <a
                            href="data:text/csv;charset=utf-8,'Top Performing TPAs 			
                                TPA Name	Captured Rx Count	Value	
                                PharmaForce	3,547	$215,124 	
                                Verity	2804	$101,225 	
                                340Basics	2500	$85,223 	
                                SunRx	1500	$62,354 '"
                            download="pharmaForce.csv"
                        >
                            {" "}
                            View Report{" "}
                        </a>
                    </div> */}
                </CardBody>
            </Card>
            <Drawer
                direction='end'
                title='Net Processed Claims by Month'
                isOpen={isOpen}
                toggle={handleProceed}
                size="lg"
            >
                <ClaimWidgetDrawer dateRange={dateRange} planId={planId} groupId={groupId} startDate={startDate} endDate={endDate} onHandleIsOpen={onHandleIsOpen}  />
            </Drawer> 
        </ErrorBoundary>
    );
};

export default ClaimsWidget;
