import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Container, Row, Label, Input,  InputGroup,
    InputGroupText, Button} from 'reactstrap';
import moment from 'moment';
import { Field, Formik } from 'formik';
import Select, { components } from 'react-select';
import ErrorBoundary from 'components/ErrorBoundary';
import ClaimsWidget from './components/ClaimsWidget';
import SavingsWidget from './components/SavingsWidget';
import TopPharmacy from './components/Widgets/TopPharmacy';
import TopDrugs from './components/Widgets/TopDrugs';
import TopProvider from './components/Widgets/TopPerformingProvider';
import Non340BVs340B from './components/Widgets/Non340BVs340B';
import useAxios from 'hooks/useAxios';
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import Calendar from 'assets/icon/calendar-icon.svg';

let hasDatePopupOpen = false;
let initValue = {
    pbmPlanId : '-1',
    pbmGroupID : '-1',
    dateRange : '3',
    startDate : null,
    endDate : null,
}
let startDateSelected = false;
let endDateSelected = false;
const Dashboard = () => {
    const API = useAxios();
    const [dateRange, setDateRange] = useState('3');
    const [planData, setPlanData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [planId, setPlanId] = useState([]);
    const [groupId, setGroupId] = useState([]);
    const [pbmPlanId, setPBMPlanId] = useState(-1);
    const [pbmGroupID, setPBMGroupId] = useState(-1);
    const [opaCode, setOPACode] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const[endDate,setEndDate]=useState(null);
    const [isHidden, setIsHidden] = useState(true);
    const [iniitalValue, setInitialValue] = useState(initValue);
    const [isSearchDisabled, SetIsSearchDisabled] = useState(true);
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);

    const daterangeoptions = [
        { value: '12', label: '12 Month' },
        { value: '6', label: '6 Month' },
        { value: '3', label: '3 Month' },
        { value:'0',label:'Custom'}
    ];

    const handleOnChange = (e)=>{
        setStartDate(null);
        setEndDate(null);
        setCustomStartDate(null);
        setCustomEndDate(null);
        startDateSelected=false;
        endDateSelected=false;
        var val = ''+e.value;
        if(e.value=='0')
        {
            setIsHidden(false);
            SetIsSearchDisabled(true);
        }
        else
        {
            setIsHidden(true);
        }
        setDateRange(val);
    }

    useEffect(()=>{
        getPlans();
    },[]);

    const getPlans = async()=>{
        try {
            const { data } = await API.post(
              `/PBMConfig/plan/getPlan`);
            if (data) {
                let options = [];
                for(var x in data)
                {
                    var row = {};
                    row.label = data[x].planDesc + '(' + data[x].planCode + ')' ;
                    row.value = data[x].pbmPlanID;
                    row.opaCode = data[x].opaCode;
                    options.push(row);
                }
                setPlanData(options);
            }
          }
          catch (error) {
      
          }
    };

    const handlePlanChange = (e)=>{
        setGroupData([]);
        setGroupId([]);
        if(e!==null)
        {
            setPlanId(e);
            setPBMPlanId(e.value);
            getGroupByCode(e.value); 
            setOPACode(e.opaCode);
            SetIsSearchDisabled(false);
        }
        else
        {
            getGroupByCode([]);
            setPlanId([]);
            setPBMPlanId(-1);
            setOPACode(null);
        }
    }

    const handleGroupChange = (e)=>{
        if(e!==null)
        {
            setGroupId(e);
            setPBMGroupId(e.value);
        }
        else{
            setPBMGroupId(-1);
            setGroupId([]);
        }
    }


    const getGroupByCode = async(planId)=>{
        try {

            var planCode = {};
            planCode.PlanID = planId;

            var param = {};
            param.filters = planCode;

            const { data } = await API.post(
              `/PBMConfig/group/getGroupByPlanID`, param);
            if (data) {
                let options = [];
                for(var x in data)
                {
                    var row = {};
                    row.label = data[x].groupDesc + '(' + data[x].groupCode + ')' ;
                    row.value = data[x].pbmGroupID
                    options.push(row);
                }
                setGroupData(options);
            }
          }
          catch (error) {
            setGroupData([]);
          }
    }

    const handleStartDate = (startDate) => {
        let stDate = moment(new Date(startDate)).format(
          'MM/DD/YYYY'
        );
        setStartDate(stDate)
        stDate = moment(new Date(startDate)).format(
            'YYYYMMDD'
          );
        setCustomStartDate(stDate);
        startDateSelected=true;
        resetInitialValues();
      };
    
      const handleEndDate = (endDate) => {
        let edDate = moment(new Date(endDate)).format(
          'MM/DD/YYYY'
        );
        setEndDate(edDate);
        edDate = moment(new Date(endDate)).format(
            'YYYYMMDD'
          );
        setCustomEndDate(edDate);
        endDateSelected=true;
        resetInitialValues();
      };

    const handleMouseLeave = () => {
        hasDatePopupOpen = false;
      };
    
      const handleDateClick = (id) => {
        if (hasDatePopupOpen) {
          hasDatePopupOpen = false;
          return;
        }
        const calendarButton = document.getElementById(id);
        if (calendarButton) {
          calendarButton.click();
          hasDatePopupOpen = true;
        }
      };

      const resetInitialValues = ()=>{
        if(!isHidden && startDateSelected==true && endDateSelected==true)
        {
            SetIsSearchDisabled(false);
        }
        else
        {
            SetIsSearchDisabled(true);
        }
      }

      const handleSearch = ()=>{
        let arr = {};
        arr.pbmPlanId = pbmPlanId;
        arr.pbmGroupID = pbmGroupID;
        arr.dateRange = dateRange;
        arr.startDate = customStartDate;
        arr.endDate = customEndDate;
        setInitialValue(arr);
      }

      const handleReset = ()=>{
        setPlanId(-1);
        setGroupId(-1);
        setPBMPlanId(-1);
        setGroupData([]);
        setStartDate(null);
        setEndDate(null);
        setCustomStartDate(null);
        setCustomEndDate(null);
        startDateSelected=false;
        endDateSelected=false;
        setIsHidden(true);
        setDateRange('3');
        let arr = {};
        arr.pbmPlanId = -1;
        arr.pbmGroupID = -1;
        arr.dateRange = dateRange;
        arr.startDate = null;
        arr.endDate = null;
        setInitialValue(arr);
      }

    return (       
        <div>
            <Container fluid className='my-2 p-4'>
              <ErrorBoundary>
                    <Formik
                        children={(props) => {
                        return(
                            <div>
                                <div className='row card p-2'>
                                    <Row className='row mt-12'>
                                        <Col md={3}>
                                            <Label>Plan</Label>
                                            <Select
                                                isClearable={true}
                                                options={planData}
                                                isMulti={false}
                                                value={planId}
                                                onChange={(e) => handlePlanChange(e)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Label>Group</Label>
                                            <Select
                                                isClearable={true}
                                                options={groupData}
                                                isMulti={false}
                                                value={groupId}
                                                onChange={(e) => handleGroupChange(e)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Label>Date Range</Label>
                                            <Select options={daterangeoptions} onChange={(e)=>handleOnChange(e)} value = {
                                                daterangeoptions.filter(option => 
                                                    option.value === dateRange)
                                                } />
                                        </Col>

                                        <Col md={5} >
                                            <Label>{""}</Label>
                                            <div style={{"display":"flex", marginTop:"3px"}}>
                                            <InputGroup style={{width:"30%"}} onMouseLeave={handleMouseLeave} hidden={isHidden}>
                                                <Field
                                                type='text'
                                                id='startDate'
                                                name='startDate'
                                                autoComplete='off'
                                                placeholder='Start date'
                                                value={startDate}
                                                
                                                component={FormikDatePicker}
                                                onChange={handleStartDate}
                                                />
                                                <InputGroupText
                                                onClick={() => handleDateClick('startDate')}
                                                >
                                                <img src={Calendar} alt='' />
                                                </InputGroupText>
                                            </InputGroup>
                                            &nbsp; &nbsp;
                                            <InputGroup style={{width:"30%"}} onMouseLeave={handleMouseLeave} hidden={isHidden}>
                                                <Field
                                                type='text'
                                                id='endDate'
                                                name='endDate'
                                                autoComplete='off'
                                                placeholder='End date'
                                                value={endDate}
                                                component={FormikDatePicker}
                                                onChange={handleEndDate}
                                                />
                                                <InputGroupText
                                                onClick={() => handleDateClick('endDate')}
                                                >
                                                <img src={Calendar} alt='' />
                                                </InputGroupText>
                                            </InputGroup>
                                            <Button
                                                    onClick={handleSearch}
                                                    color='primary'
                                                    className='ms-3'
                                                    disabled={isSearchDisabled}
                                                    >
                                                    Search
                                                </Button>
                                                <Button color='primary' onClick={() => handleReset()}>
                                                    Reset
                                                </Button>
                                            </div>
                                         
                                        </Col>
                                    </Row>
                                    {/* <Row className='row mt-12' hidden={isHidden}>
                                        <Col md={7}>{""}</Col>
                                        
                                    </Row> */}
                                    </div>
                            <Row sm={2} xs={1}>
                                <Col className='mt-3' md={6}>
                                    <ClaimsWidget dateRange={iniitalValue.dateRange} planId={iniitalValue.pbmPlanId} groupId={iniitalValue.pbmGroupID} startDate={iniitalValue.startDate} endDate={iniitalValue.endDate} />
                                </Col>
                                <Col className='mt-3' md={6}>
                                    <SavingsWidget dateRange={iniitalValue.dateRange} planId={iniitalValue.pbmPlanId} groupId={iniitalValue.pbmGroupID} startDate={iniitalValue.startDate} endDate={iniitalValue.endDate}/>
                                </Col>
                                {/* <Col className='mt-3' md={4}>
                                    <RevenueMonthWidget dateRange={dateRange}/>
                                </Col> */}
                            </Row>
                            <Row sm={2} xs={1}>
                                <Col className='mt-3'>
                                    <TopPharmacy dateRange={iniitalValue.dateRange} planId={iniitalValue.pbmPlanId} groupId={iniitalValue.pbmGroupID} startDate={iniitalValue.startDate} endDate={iniitalValue.endDate}/>
                                </Col>
                                <Col className='mt-3'>
                                    <TopDrugs dateRange={iniitalValue.dateRange} planId={iniitalValue.pbmPlanId} groupId={iniitalValue.pbmGroupID} startDate={iniitalValue.startDate} endDate={iniitalValue.endDate}/>
                                </Col>
                                <Col className='mt-3'>
                                    <TopProvider dateRange={iniitalValue.dateRange} planId={iniitalValue.pbmPlanId} groupId={iniitalValue.pbmGroupID} startDate={iniitalValue.startDate} endDate={iniitalValue.endDate}/>
                                </Col>
                                <Col className='mt-3'>
                                    <Non340BVs340B dateRange={iniitalValue.dateRange} planId={iniitalValue.pbmPlanId} groupId={iniitalValue.pbmGroupID} startDate={iniitalValue.startDate} endDate={iniitalValue.endDate}/>
                                </Col>
                            </Row>
                            
                            </div>
                            
                        )
                        }}
                    >
                    </Formik>
                </ErrorBoundary>
            </Container>
        </div>
    );
};
export default Dashboard;
