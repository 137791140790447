import React from "react";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "formik";
import { Label } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

const FormikDatePicker = ({

  field: { name, value },
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { id, placeholder, label } = props;
  let formatedDate = "";

  if (value) {
    formatedDate = new Date(value);
  }
  else if (props.value) {
    formatedDate = new Date(props.value);
  }
  const handleChange = (date) => {
    setFieldValue(name, date, true);
  };

  return (
    <div className="form-control inner-shadow-field p-0">
      {label && (
        <Label for={name} className="d-block">
          {label}
        </Label>
      )}
      <DatePicker
        id={id}
        invalid={Boolean(touched[name] && errors[name])}
        selected={formatedDate}
        onChange={handleChange}
        name={name}
        dateFormat="MM/dd/yyyy"
        placeholderText={placeholder}
        {...props}
        className="border-0 w-100 date-picker"
      />
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger font-size-12">{msg}</div>}
      />
    </div>
  );
};
export default FormikDatePicker;
