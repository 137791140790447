import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './style/pfapp.scss';

import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import NotValidTab from 'pages/NotValidTab';
import { PrivateRoute } from 'routes/privateRoute';

import axios from 'axios';


const App = () => {
  const [modules, setModules] = useState([])

    
  const renderDashBoard = () => {
    return (<Route path='/dashboard' element={<Dashboard module={module[0]} />} />)
  }

  const renderLogin = () => {
    return (<Route index element={<Login />} />)
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Outlet />}>
            <Route element={<PrivateRoute modules={modules} />}>
              {renderLogin()}
              {renderDashBoard()}
            </Route>
            {/* catch all */}
            <Route path='*' element={<NotValidTab />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position='top-right' autoClose={3000} />
    </>
  );
};

export default App;
