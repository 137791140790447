import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Button
} from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import useAxios from 'hooks/useAxios';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

import ErrorBoundary from 'components/ErrorBoundary';
import CustomeDate from './customeDate';
import Drawer from 'components/Drawer';
import QuestionIcon from 'assets/icon/question-mark.svg';
import Non340BVs304BDrawer from 'pages/Dashboard/components/GridData/Non340BVs304BDrawer';
const Non340BVs340B = ({dateRange, planId, groupId, startDate, endDate}) => {
  const API = useAxios();
  const [active, setActive] = useState('3');
 // const [labels, setLabels] = useState(null);
  const [graphData, setGraphData] = useState(null);
  //const [options, setOptions] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [disabledViewButtom, setDisabledViewButtom] = useState(true);

  useEffect(()=>{
    setActive(dateRange);
    handleActive(dateRange);
    getEstimate340BSpend();
  },[dateRange, planId, groupId, startDate, endDate]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          // title: function (item,index) {
          //   const {label} =item[0].dataset;
          //   return `${item[0].label} \n ${label}`;
          // },
            label: function (d,t) {
            var xLabel = "Spend";
            var yLabel = '$' + d.formattedValue;
            return xLabel + ': ' + yLabel;
          },
        },
      },
      legend: {
        position: 'top',
        labels: {
          padding: 15,
          usePointStyle: true,
          pointStyle: 'rect',
          color: '#707070',
          font: {
            weight: 'bold',
          },
        },
        align: 'start',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        border: {
            display: false,
        },
        ticks: {
            // include a dollar sign in the ticks
            callback: function(value, index, values) {
                if(value>=1000)
                {
                    return (
                        '$' + (value/1000) + 'k'
                    );
                }
                else
                {
                    return (
                        '$' + value
                    );
                }
               
            }
        },
    },
    },
  };

  const getEstimate340BSpend = async()=>{
    const { data } = await API.get(
      `/PBMTenant/${planId}/PBMDashboard/getestimated340bsaving/${groupId}/${dateRange}/${startDate}/${endDate}`);
      if(data)
      {
        let labels = data.labels;
        //setLabels(data.labels);

          let gphData = {
          labels,
          datasets: [
            {
              type: 'bar',
              label: 'Total Spend',
              backgroundColor: '#98CE8F',
              data: data.totalSpend,
              borderColor: '#98CE8F',
              fill: false,
              borderWidth: 2,
              order:1
            },
            {
              type: 'line',
              label: 'Total Spend After 340B Saving',
              borderColor: '#B54DA0',
              backgroundColor: '#B54DA0',
              borderWidth: 2,
              fill: false,
              //data: labels.map(() => Math.floor(Math.random() * (100 - 1 + 1)) + 1),
              data: data.savingAfter340B,
              order:0
            },
          ],
        };
        setGraphData(gphData);
        if(data.totalSpend!=null && data.totalSpend.length>0)
        {
            setDisabledViewButtom(false);
        }
        else
        {
            setDisabledViewButtom(true);
        }
      }
  }

  
  const handleActive = (title) => {
    setActive(title);
  };

  const plugin = {
    beforeInit(chart) {
      console.log('be');
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
  
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height = this.height + 25;
      };
    },
  };

    const getDate = () => {
   setActive("custom");
  };

  const handleProceed = ()=>{
    setIsOpen(!isOpen);
}
const onHandleIsOpen = (hasRefresh)=> {
    if (isBoolean(hasRefresh) && hasRefresh) {
        onClose(true);
    }
    setIsOpen(!isOpen)
  }
  return (
    <ErrorBoundary>
      <Card className='p-3 h-100'>
        <CardHeader className='border-0 bg-white'>
          <Row className='align-items-center'>
            <Col>
              <CardTitle className='f-20 font-weight-500 mb-0'>
                 340B Impact to Total Spend
                 <span className='qu_icon' style={{"paddingLeft":"10px"}} data-title={"The Total Spend bar is the total Plan Paid amount for the net processed, non-reversed claims for the specified timeframe. The Total Spend after 340B Savings line is the Total Spend minus the contract pharmacy calculated 340B savings. Showing you your reduced spend resulting from 340B eligible claims."}>
                      <img src={QuestionIcon} style={{"width":"20px"}} />
                  </span>
              </CardTitle>
            </Col>
            <Col xs='auto' hidden={true}>
              <Nav className='widget-tabs'>
                <NavItem>
                  <NavLink
                    className={
                      active === '3'
                        ? 'active-tab f-12 cursor-pointer'
                        : 'f-12 cursor-pointer'
                    }
                    onClick={() => handleActive('3')}
                  >
                    3 Mon
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      active === '6'
                        ? 'active-tab f-12 cursor-pointer'
                        : 'f-12 cursor-pointer'
                    }
                    onClick={() => handleActive('6')}
                  >
                    6 Mon
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      active === '12'
                        ? 'active-tab f-12 cursor-pointer'
                        : 'f-12 cursor-pointer'
                    }
                    onClick={() => handleActive('12')}
                  >
                    1 Yr
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={
                      active === 'custom'
                        ? 'active-tab f-12 cursor-pointer'
                        : 'f-12 cursor-pointer'
                    }>
                    <CustomeDate getDate={getDate} active={active} />
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className='dashboard-chart-body'>
         <div className='dashboard-horizontal-combochart-height'>
         {/* <Bar options={options} data={chartData} plugins={[plugin]}/> */}
         {
            graphData && (<Chart type='bar' data={graphData} options={options} />)
         }

          <div className="view-report">
              <Button
                  color='primary'
                  className='ms-2'
                  onClick={handleProceed}
                  style={{ float: "right",marginTop:"26px" }}
                  disabled={disabledViewButtom}
              >
                  View Report
              </Button>
          </div>


         </div>
        
        </CardBody>
      </Card>
      <Drawer
            direction='end'
            title='340B Impact to Total Spend'
            isOpen={isOpen}
            toggle={handleProceed}
            size="lg"
        >
            <Non340BVs304BDrawer dateRange={dateRange} planId={planId} groupId={groupId} startDate={startDate} endDate={endDate} onHandleIsOpen={onHandleIsOpen}  />
        </Drawer> 
    </ErrorBoundary>
  );
};

export default Non340BVs340B;
